import React from 'react';

import { useWatch } from 'antd/es/form/Form';
import { InputNumber, Form, FormInstance } from '~/UI';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { EnergyTerm, SolarAdvancedInputs } from '~/store/project';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import { GenericLabel } from '../FormGroup';
import { RACKING_TYPE } from '~/utils/rackingTypes';

type Props = {
  defaultAdvancedInputs: SolarAdvancedInputs;
  disabledInputs?: boolean;
  testIdData?: TestIdProps;
  form: FormInstance;
  rackingType?: string;
};

const SolarAdvancedInputsFormGroup = ({
  form,
  defaultAdvancedInputs,
  disabledInputs = false,
  testIdData,
  rackingType,
}: Props) => {
  const currentEnergyTerms: EnergyTerm[] = useWatch('energy_terms', form);

  return (
    <>
      <Form.Item
        name="target_rate_of_return_percent"
        initialValue={defaultAdvancedInputs?.target_rate_of_return_percent}
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'energy-rate-discount',
              } as TestIdProps
            }
            title="Discount Rate"
            fontSize="14px"
            popoverContent="The Discount rate is applied to future cash flows to adjust for the time value of money. "
          />
        }
        labelCol={{ span: 24 }}
        rules={
          SOLAR_INPUT_CONFIG.advancedInputs.target_rate_of_return_percent.rules
        }
      >
        <InputNumber
          {...SOLAR_INPUT_CONFIG.advancedInputs.target_rate_of_return_percent
            .inputNumberProps}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'energy-rate-discount',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="project_term_years"
        initialValue={defaultAdvancedInputs?.project_term_years}
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'project-term',
              } as TestIdProps
            }
            title="Project Term"
            fontSize="14px"
            popoverContent="Number of years to include in the cashflow model"
          />
        }
        labelCol={{ span: 24 }}
        rules={SOLAR_INPUT_CONFIG.advancedInputs.project_term_years.generateRules(
          currentEnergyTerms
        )}
      >
        <InputNumber
          {...SOLAR_INPUT_CONFIG.advancedInputs.project_term_years
            .inputNumberProps}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'project-term',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="baseline_ac_size_kW"
        initialValue={defaultAdvancedInputs?.baseline_ac_size_kW}
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'ac-size',
              } as TestIdProps
            }
            title="AC Size"
            fontSize="14px"
            popoverContent="The combined power of inverters."
          />
        }
        labelCol={{ span: 24 }}
        rules={SOLAR_INPUT_CONFIG.advancedInputs.baseline_ac_size_kW.rules}
      >
        <InputNumber
          {...SOLAR_INPUT_CONFIG.advancedInputs.baseline_ac_size_kW
            .inputNumberProps}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'ac-size',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="baseline_ground_coverage_ratio"
        initialValue={defaultAdvancedInputs?.baseline_ground_coverage_ratio}
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'ground-coverage-ratio',
              } as TestIdProps
            }
            title="Ground Coverage Ratio"
            fontSize="14px"
            popoverContent="The % of ground area covered by PV modules."
          />
        }
        labelCol={{ span: 24 }}
        rules={
          SOLAR_INPUT_CONFIG.advancedInputs.baseline_ground_coverage_ratio.rules
        }
      >
        <InputNumber
          {...SOLAR_INPUT_CONFIG.advancedInputs.baseline_ground_coverage_ratio
            .inputNumberProps}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'ground-coverage-ratio',
          })}
          disabled={disabledInputs || rackingType === RACKING_TYPE.ROOFTOP_MLPE}
        />
      </Form.Item>
    </>
  );
};

export default SolarAdvancedInputsFormGroup;
