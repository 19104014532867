import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getConfiguration } from '~/utils/configurations';

import { AddressSearch, InputAnt as Input, Form, FormInstance } from '~/UI';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';
import { SolarAdvancedInputs, SolarProject } from '~/store/project';
import { PROJECT_STATUSES, ProjectTypes } from '~/utils';
import AdvancedInputsBanner from '~/components/AdvancedInputsBanner';

import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import useAuth from '~/hooks/useAuth';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';

import AdvancedInputFormGroup from './SolarAdvancedInputsFormGroup';
import {
  StyledCollapse,
  StyledCollapsePanel,
  StyledForm,
} from '../shared/styles';
import SolarFormGroup from './SolarFormGroup';
import { GenericLabel, ProjectLocationLabel } from '../FormGroup';

interface Props {
  form: FormInstance;
  data: SolarProject;
  defaultAdvancedInputs: SolarAdvancedInputs;
  disabledInputs?: boolean;
  disabledAdvancedInputs?: boolean;
}

const ProjectSolarDrawerForm = ({
  form,
  data,
  defaultAdvancedInputs,
  disabledInputs = false,
  disabledAdvancedInputs,
}: Props) => {
  const [locationError, setLocationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [displayPricingBanner, setDisplayPricingBanner] = useState(false);

  const maybeShowDisplayPricingBanner = () => {
    const projectSize = data.project_details.size_in_kw;
    const previousPurchaseVolumeKw = data.previous_purchase_volume_kw;
    const currentPurchaseVolume = form.getFieldValue(
      'current_purchase_volume_kw'
    );
    setDisplayPricingBanner(
      currentPurchaseVolume + previousPurchaseVolumeKw >= projectSize
    );
  };

  useEffect(() => {
    form.validateFields();
    form.resetFields();
    maybeShowDisplayPricingBanner();
  }, [data]);

  const handleSelectLocation = (location: AddressSearchResponseType) => {
    setLocationError('');
    if (location?.error?.message) {
      setLocationError(location?.error?.message);
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        google_place_id: null,
        state: null,
      });
      form.validateFields(['project_address']);
      return;
    }
    form.setFieldsValue({
      project_address: location.address,
      latitude: location.lat,
      longitude: location.lng,
      google_place_id: location.placeId || '',
      state: location.state,
    });
    form.validateFields(['project_address']);
  };

  const handleFormBlur = () => {
    maybeShowDisplayPricingBanner();
  };

  const projectStatus = data.project_details.status;

  const { user } = useAuth();
  const isEngagedSolar = user.company?.engaged_solar;
  const showEngagingBanner = !isEngagedSolar;

  return (
    <StyledForm
      name="solar-project-details"
      form={form}
      initialValues={{
        name: data.name || '',
        project_address: data.project_details.project_address || '',
        arrival_date: data.project_details.arrival_date
          ? dayjs(data.project_details.arrival_date)
          : null,
        anticipated_purchase_date: data.project_details
          .anticipated_purchase_date
          ? dayjs(data.project_details.anticipated_purchase_date)
          : null,
        size_in_kw: data.project_details.size_in_kw,
        racking_type: data.project_details.racking_type,
        latitude: data.project_details.latitude,
        longitude: data.project_details.longitude,
        location_id: data.project_details.location_id,
        state: data.project_details.state,
        baseline_ac_size_kW: data.advancedInputs?.baseline_ac_size_kW,
        target_rate_of_return_percent:
          data.advancedInputs?.target_rate_of_return_percent,
        energy_rate_value_USD_per_kWh:
          data.advancedInputs?.energy_rate_value_USD_per_kWh,
        energy_rate_escalator_percent:
          data.advancedInputs?.energy_rate_escalator_percent,
        project_term_years: data.advancedInputs?.project_term_years,
        baseline_ground_coverage_ratio:
          data.advancedInputs?.baseline_ground_coverage_ratio,
      }}
      layout="vertical"
      onBlur={handleFormBlur}
    >
      <Form.Item
        name="name"
        label={
          <GenericLabel
            testIdData={{
              component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
              identifier: 'project-name',
            }}
            title="Project Name"
            fontSize="14px"
          />
        }
        labelCol={{ span: 24 }}
        rules={PROJECT_INPUT_CONFIG.name.rules}
      >
        <Input
          {...PROJECT_INPUT_CONFIG.name.inputProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'project-name',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="project_address"
        label={
          <ProjectLocationLabel
            testIdData={{
              component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
            }}
            fontSize="14px"
          />
        }
        rules={PROJECT_INPUT_CONFIG.projectAddress.applyRules(
          isLoading,
          form,
          locationError
        )}
      >
        <AddressSearch
          disabled
          onSelectLocation={handleSelectLocation}
          isLocationLoading={setIsLoading}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.AUTOCOMPLETE}`,
            identifier: 'address-search',
          })}
          onValueChange={(value) => {
            if (value) {
              form.setFieldsValue({
                latitude: null,
                longitude: null,
                google_place_id: null,
              });
            }
          }}
        />
      </Form.Item>
      <SolarFormGroup
        disabledInputs={disabledInputs}
        form={form}
        isEditing
        projectStatus={projectStatus}
        previousPurchaseVolumeKw={data.previous_purchase_volume_kw}
        displayPricingBanner={displayPricingBanner}
        testIdData={{ component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER }}
      />
      {[PROJECT_STATUSES.CREATED, PROJECT_STATUSES.PROPOSAL].includes(
        projectStatus
      ) && (
        <StyledCollapse
          defaultActiveKey={['1']}
          bordered={false}
          expandIconPosition="right"
          ghost
        >
          <StyledCollapsePanel header="Advanced Inputs" key="1">
            <AdvancedInputsBanner
              learnMoreUrl={getConfiguration('REACT_APP_CALENDLY_SOLAR_URL')}
              disable={!showEngagingBanner}
              projectType={ProjectTypes.SOLAR}
            />
            <AdvancedInputFormGroup
              form={form}
              defaultAdvancedInputs={defaultAdvancedInputs}
              disabledInputs={disabledAdvancedInputs}
              testIdData={{
                component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
              }}
              rackingType={data.project_details.racking_type}
            />
          </StyledCollapsePanel>
        </StyledCollapse>
      )}
      <Form.Item
        name="state"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="latitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="longitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="google_place_id"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
    </StyledForm>
  );
};

export default ProjectSolarDrawerForm;
